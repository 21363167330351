<template>
  <div class="xz-bgPlayColor p-b-20">
    <el-row class="bg-white text-l">
      <div class="xz-b" v-show="calssSearchType == '2'" >
        <el-col :span="24" class="m-t-10" v-show="classList.length > 0">
          <p class="f-l-l f-s-14 m-r-10" style="color: #636161">课程分类:</p>
          <el-button
            type="text"
            v-for="(item, i) in classList"
            :key="i"
            @click="changeActive(item)"
            :class="{'activeStyle':item.itid == classActive,'noActive':item.itid != classActive}"
            >{{ item.itname }}</el-button
          >
        </el-col>
        <el-col :span="24" class="m-t-10 m-b-10" v-show="false">
          <p class="f-l-l f-s-14 m-r-10" style="color: #636161">课程类型:</p>
          <el-button
            type="text"
            v-for="(item, i) in classTypeList"
            :key="i"
            :class="{'activeStyle':item.id == classTypeActive,'noActive':item.id != classTypeActive}"
            @click="changeType(item)"
            >{{ item.text }}</el-button
          >
        </el-col>
        <el-col :span="24" v-if="classIfyList.length > 0" class="m-b-10">
          <p class="f-l-l f-s-14 m-r-10" style="color: #636161">课程类型:</p>
          <el-row>
            <el-col :span="22">
              <el-button
                type="text"
                v-for="(item, i) in classIfyList"
                :key="i"
                :class="{'activeStyle':item.itid == classIfy,'noActive':item.itid != classIfy}"
                @click="changeIfy(item)"
                >{{ item.itname }}</el-button
              >
            </el-col>

          </el-row>

        </el-col>
      </div>
      <div class="xz-b" v-show="calssSearchType == '1'">
          <el-input
            style="min-width:600px;margin:0 auto;width:60%;margin-left:20%"
            placeholder="搜索课程"
            @keyup.enter.native="searchClassList"
            suffix-icon="el-icon-search"
            class="m-t-10 m-b-10 classSearch"
            clearable
            v-model="searchText">
          </el-input>
          <el-button type="text" class="m-l-10" @click="changeClassType">切换</el-button>
      </div>
    </el-row>
    <div class="xz-b">
      <Item v-show="ExcellentClassList.length > 0" :ExcellentClassList="ExcellentClassList" type="curr" />
      <el-result icon="info" title="小筑教育" subTitle="暂无更多课程" v-show="ExcellentClassList.length <= 0">
        <template slot="extra">
          <!-- <el-button type="primary" size="medium">返回</el-button> -->
        </template>
      </el-result>
    </div>
  </div>
</template>

<script>
import Item from "@/components/public/ExcellentClass/item";

export default {
  props:{
    classType:{  // 当前组件在哪 1 在考试报名打开的
      Type:String,
      default:"",
    }
  },
  components: {
    Item,
  },
  created() {
    this.getExceAllList(); // 获取精品课程的全部列表
    this.getClassList(); // 获取分类列表
  },
  data() {
    return {
      ExcellentClassList: [],
      classList: [],
      classTypeList: [
        { id: '0', text: "全部" },
        { id: '1', text: "免费课" },
        { id: '2', text: "精品课" },
      ],
      classIfyList: [],
      classActive: 0,
      classTypeActive: '0',
      classIfy: 0,
      searchText:''
    };
  },
  computed:{
    // 1 input筛选  2 专业类型筛选
    calssSearchType(){
      return this.$store.state.learn.calssSearchType
    },
    editSearchTextChange(){
      return this.$store.state.learn.searchTextChange
    },
    homeActive(){
      return this.$store.state.learn.homeActive
    }
  },
  methods: {
    changeClassType(){
      let val = '1'
      if(this.calssSearchType == '1') val = '2'
      this.$store.commit("learn/editClassSearchType",val)
      this.getExceAllList()
    },
    // 搜索课程
    searchClassList(){
      this.getExceAllList()
    },
    getExceAllList() {
      let data = {}
      if(this.calssSearchType == '1') {
        data.cname = this.searchText
      } else if(this.calssSearchType == '2') {
        data.itid = this.classActive
        data.itidTwo = this.classIfy
        data.courseContent = this.classTypeActive
      }
      if(this.classType != "") {
        data.courseContent = "2"
      }
      this.Api.Curr.getExceAllList(data).then((res) => {
        this.ExcellentClassList = res.data;
      });
    },
    getClassList() {
      this.Api.Login.itemFirstLevel().then((res) => {
        if (res.retuCode == 200) {
          this.classList = res.data;
        }
      });
    },
    changeActive(item) {
      if(item == undefined) {
        item = {
          itid : this.classActive
        }
      }
      this.classActive = item.itid;
      let data = {
        itid: item.itid,
      };
      this.Api.Login.getSecLevel(data).then((res) => {
        if (res.retuCode == 200) {
          this.classIfy = 0
          this.classIfyList = res.data;
          this.getExceAllList()
        }
      });
    },
    changeType(item){
      this.classTypeActive = item.id
      this.getExceAllList()
    },
    changeIfy(itme){
      this.classIfy = itme.itid 
      this.getExceAllList()
    }
  },
  watch:{
    editSearchTextChange:{
      deep:true,
      immediate:true,
      handler(val) {
        if(val != '') {
          this.searchText = val
          this.getExceAllList()
        }
      }
    },
    homeActive:{
      deep:true,
      immediate:true,
      handler(val) {
        if(val != '') {
          this.classActive = val
          this.changeActive()
        }
      }
    }
  }
};
</script>

<style scoped>
.classSearch >>> input:focus {
  border-color: #F08142;
}
p {
  margin: 0;
  height: 40px;
  line-height: 40px;
}
.activeStyle {
  background: #F08041;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 20px;
  margin-left: 0;
  margin-right: 10px;
}
.noActive{
  color: #333333;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 0;
  margin-right: 10px;
}
.classSearch >>> input {
  border-radius: 40px;
}
</style>